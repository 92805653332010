import React, { createContext, useState, useEffect, useContext } from 'react';
import { getToken, removeToken, setToken } from '../service/auth';
import { SpinnerComponent } from '../components/Spinner';
import { api } from '../service/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIstloading] = useState(true);
  const token = getToken();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        console.log("fetchUserProfile => Token", token);
        if (token) {
          const response = await api.get('/auth/profile');
          console.log("fetchUserProfile => ", response.data.data.user);
          setUser(response.data.data.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Erro ao buscar perfil do usuário:', error);
        setUser(null);
      } finally {
        setIstloading(false);
      }
    };
    fetchUserProfile();
  }, [token]);

  const login = async (user, token) => {
    try {
      setUser(user);
      setToken(token);
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  };

  const logout = () => {
    removeToken();
    setUser(null);
  };

  if (isLoading) {
    return <SpinnerComponent />;
  }

  // if (!isLoading && !user) {
  //   console.log("Its Okey")
  //   window.location.href = 'https://apps.palmaseguros.tech';
  //   return false;
  // }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

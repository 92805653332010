export const TOKEN_KEY = "PalmaTrackerToken";
export const USERDATA = "PalmaTrackerUser";

export const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return token !== null;
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return token;
};
export const getUser = () => {
  const user = localStorage.getItem(USERDATA);
  return JSON.parse(user);
};

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const setUser = (user) => {
  const userFormatted = JSON.stringify(user);
  localStorage.setItem(USERDATA, userFormatted);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USERDATA);
};
